import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { LazyImage } from 'react-lazy-images';

import Styles from './Img.module.scss';

// eslint-disable-next-line react/prop-types
const Img = ({ className, wrapperClassName, height, width, src, alt, style, ...props }) => {
  return (
    <span
      className={classNames(wrapperClassName, Styles.container)}
      style={{ display: 'inline-block', color: 'transparent', ...style, height, width }}>
      <img
        className={classNames(className, Styles.fadeIn)}
        loading='lazy'
        src={src}
        alt={alt}
        height={height}
        width={width}
        {...props}
      />
      {/*<LazyImage
        src={src}
        alt={alt}
        {...props}
        debounceDurationMs={0}
        placeholder={({ ref, imageProps }) => (
          <span ref={ref} {...imageProps} style={{ visibility: 'hidden', height, width }} />
        )}
        actual={({ imageProps }) => (
          <img
            {...imageProps}
            loading='lazy'
            height={height}
            width={width}
            alt={alt}
            className={classNames(className, Styles.fadeIn)}
          />
        )}
        observerProps={{
          rootMargin: '150px 0%',
          threshold: 0.01,
        }}
      />*/}
    </span>
  );
};

Img.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default Img;
