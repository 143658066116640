import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Hero from '../Hero';
import HeroButton from '../HeroButton';

import UserMasonry from '../../../UserMasonry/UserMasonry';
import Styles from './Styles.module.scss';
// const UserMasonry = dynamic(() => import('../../../UserMasonry/UserMasonry'), { ssr: false });

const MainHero = ({
  users,
  order,
  title,
  description,
  talentCtaLinkTo,
  talentCtaLabel,
  brandCtaLinkTo,
  brandCtaLabel,
  customerNames,
  analyticsData,
  ...props
}) => {
  return (
    <>
      <Hero parent={Hero.Page.MAIN}>
        <div className={Styles.container}>
          <div className={Hero.Styles.heroLeft}>
            <div>
              <h1 className={Styles.heroTitle}>{title}</h1>
              <p className={Styles.heroMainDes}>{description}</p>
              <div className={classNames(Styles.mainHeroCta)}>
                <HeroButton
                  type={HeroButton.Type.WHITE}
                  className={Styles.btnCta}
                  title=''
                  href={{
                    pathname: brandCtaLinkTo,
                    query: { ...analyticsData },
                  }}>
                  {brandCtaLabel}
                </HeroButton>

                <HeroButton
                  type={HeroButton.Type.WHITE_OUTLINE}
                  className={Styles.btnCta}
                  title=''
                  href={{
                    pathname: talentCtaLinkTo,
                    query: { ...analyticsData },
                  }}>
                  {talentCtaLabel}
                </HeroButton>
              </div>
            </div>
          </div>
          <div className={Hero.Styles.heroRight}>
            {users && <UserMasonry bottom users={users} order={order || users} />}
          </div>
        </div>
      </Hero>
    </>
  );
};

MainHero.propTypes = {
  users: PropTypes.array,
  order: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  talentCtaLinkTo: PropTypes.string,
  talentCtaLabel: PropTypes.string,
  brandCtaLinkTo: PropTypes.string,
  brandCtaLabel: PropTypes.string,
  customerNames: PropTypes.array,
};

export default MainHero;
