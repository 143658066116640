// import ButtonLink from '@hiredigital/ui/Button/NextButtonLink';
import Img from '@components/Img';
import Column from '@components/Column/Column';
import { Section, SectionContainer } from '@components/Section';
import Styles from './Styles.module.scss';
const TalentTypes = () => {
  return (
    <SectionContainer anchor='talent'>
      <Section
        title='Receive Curated Talent Recommendations'
        subtitle='We have helped our clients build teams for software development, digital marketing and design. With digital skills, domain expertise, and adaptability to succeed, our talents have improved ROI for organizations around the world.'>
        <Column.List isCardList>
          <Column.Card className={Styles.card} href='/why'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='teams'
                alt='teams'
                src={`/img/talent-types/multiple.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>
              {'Multi-Talent, Managed Teams'}
            </Column.Headline>
            <Column.Content>
              {`On Demand Teams, Digital Transformation Experts and more. Need to assemble a team? Get in touch with a Hire Digital Teams specialist.`}
            </Column.Content>
            {/* <Column.Action>
              <ButtonLink
                type={ButtonLink.Type.BLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/why'>
                {'Learn More about Teams'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>
          <Column.Card className={Styles.card} href='/marketing'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='Digial Marketing Experts'
                alt='Digial Marketing Experts'
                src={`/img/talent-types/marketing.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>
              {'Digital Marketing Experts'}
            </Column.Headline>
            <Column.Content>
              {
                'Social Media Marketers, SEO Experts, Digital PR Experts, PPC Experts, Content Strategists, Conversion Optimization Experts, Researchers and more.'
              }
            </Column.Content>
            {/*<Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/marketing'>
                {'Work With Digital Marketers'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>
          <Column.Card className={Styles.card} href='/developers'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='developers'
                alt='developers'
                src={`/img/talent-types/develop.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>{'Developers'}</Column.Headline>
            <Column.Content>
              {
                'Front-end Developers, Tagging Developers, Creative Technologists, Back-end Developers, Data Analysts and more.'
              }
            </Column.Content>
            {/*<Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/developers'>
                {'Work With Developers'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>

          <Column.Card className={Styles.card} href='/designers'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='designers'
                alt='designers'
                src={`/img/talent-types/design.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>{'Designers'}</Column.Headline>
            <Column.Content>
              {
                'UI Designers, UX Researchers, Graphic Designers, Illustrators, Visual Designer, Interactive Designers, Art Directors and more.'
              }
            </Column.Content>
            {/*<Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/designers'>
                {'Work With Designers'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>
          <Column.Card className={Styles.card} href='/writers'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='writers'
                alt='writers'
                src={`/img/talent-types/writer.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>{'Writers'}</Column.Headline>
            <Column.Content>
              {
                'Website, Social Media, Email Newsletter, White Paper and Presentation Copywriters. Case Study, Thought Leadership Writers and more.'
              }
            </Column.Content>
            {/*<Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/writers'>
                {'Work With Writers'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>

          <Column.Card className={Styles.card} href='/marketing/project-manager'>
            <Column.Image type={Column.Image.Type.ICON}>
              <Img
                className={Styles.image}
                height={75}
                width={75}
                title='writers'
                alt='writers'
                src={`/img/talent-types/manager.svg`}
              />
            </Column.Image>
            <Column.Headline className={Styles.title}>{'Project Managers'}</Column.Headline>
            <Column.Content>
              {
                'Project Managers, Scrum Masters, Agile Managers, Kanban Managers, Product Managers, Technical Project Managers, and more.'
              }
            </Column.Content>
            {/*<Column.Action>
              <ButtonLink
                type={ButtonLink.Type.DARKBLUE}
                size={ButtonLink.Size.FULLWIDTH_LARGE}
                title=''
                href='/marketing/project-manager'>
                {'Work With Project Managers'}
              </ButtonLink>
            </Column.Action>*/}
          </Column.Card>
        </Column.List>
      </Section>
    </SectionContainer>
  );
};
export default TalentTypes;
