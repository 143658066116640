import { memo, Fragment } from 'react';
import classNames from 'classnames';
import Link from '../Link';
import Styles from './Column.module.scss';

// Having one file with all components takes up less than half the space in yarn analyze
// I have moved all others to archive for now

const Column = memo(({ children }) => {
  return <Fragment>{children}</Fragment>;
});

const Item = memo(({ flex, className, childClassName, children, ...props }) => {
  return (
    <div className={classNames(Styles.item, className)} style={{ flex }}>
      <div className={classNames(Styles.itemChild, childClassName)} {...props}>
        {children}
      </div>
    </div>
  );
});

const Content = memo(({ className, children, ...props }) => {
  return (
    <p className={classNames(Styles.content, className)} {...props}>
      {children}
    </p>
  );
});

const Card = memo(({ className, children, href, noHoverShadow, ...props }) => {
  let Elem = 'div';
  if (href) {
    Elem = Link;
  }
  return (
    <Elem
      className={classNames(Styles.card, noHoverShadow && Styles.noHoverShadow, className)}
      href={href}
      {...props}>
      {children}
    </Elem>
  );
});

const Headline = memo(({ className, children, ...props }) => {
  return (
    <h3 className={classNames(Styles.headline, className)} {...props}>
      {children}
    </h3>
  );
});

const ImageType = {
  DEFAULT: Styles.image,
  ICON: Styles.icon,
  SMALLER_ICON: Styles.smallerIcon,
};

const Image = memo(({ className, children, type, ...props }) => {
  return (
    <div className={classNames(type, className)} {...props}>
      {children}
    </div>
  );
});
Image.Type = ImageType;

const Action = memo(({ className, children, ...props }) => {
  return (
    <div className={classNames(Styles.action, className)} {...props}>
      {children}
    </div>
  );
});

const List = memo(({ className, isCardList, children, ...props }) => {
  return (
    <div className={classNames(Styles.list, isCardList && Styles.cardList, className)} {...props}>
      {children}
    </div>
  );
});

Column.Content = Content;
Column.Headline = Headline;
Column.Image = Image;

Column.Item = Item;
Column.Card = Card;
Column.Action = Action;
Column.List = List;

export default Column;
