import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Section, SectionContainer } from '@components/Section';
import Image from 'next/image';

import Styles from './LogoSection.module.scss';

const defaultLogoNames = [
  'axa',
  'expedia',
  'roche',
  'unilever',
  'samsung',
  'marriott',
  'thunes',
  'shopify',
  'philips',
  'reckitt',
];

const LogoSection = ({
  containerClassName,
  className,
  imageContainerClassName,
  imageClassName,
  logos = defaultLogoNames,
  isColor = false,
  removeBackground = false,
  opacity = 100,
}) => {
  const finalLogos = logos.length <= 6 ? defaultLogoNames : logos;
  const [mappedLogos, setMappedLogos] = useState();

  const showLogos = () => {
    // This technique is to reduce the main execution thread
    const items = [];

    finalLogos.map((logo, index) => {
      items.push(
        <div className={classNames(Styles.imageContainer, imageContainerClassName)} key={index}>
          <Image
            alt={logo}
            height={50}
            width={100}
            layout='intrinsic'
            className={classNames(Styles.image, imageClassName)}
            src={`/img/logos/${isColor ? 'color/' : 'white/'}${logo}.svg`}
          />
        </div>
      );
    });
    setMappedLogos(items);
  };

  if (!mappedLogos && finalLogos.length) {
    showLogos();
  }

  return (
    <SectionContainer
      anchor='logos'
      className={classNames(
        Styles.sectionContainer,
        removeBackground && Styles.removeBackground,
        containerClassName
      )}
      style={{ opacity: `${opacity}%` }}>
      <Section className={classNames(Styles.section, className)}>
        <div className={classNames(Styles.marquee)}>
          {mappedLogos}
          {mappedLogos}
          {/*          {finalLogos.map((logo, index) => {
            return (
              <div
                className={classNames(Styles.imageContainer, imageContainerClassName)}
                key={index}>
                <Image
                  alt={logo}
                  height={50}
                  width={100}
                  layout='intrinsic'
                  className={classNames(Styles.image, imageClassName)}
                  src={`/img/logos/${isColor ? 'color/' : 'white/'}${logo}.svg`}
                />
              </div>
            );
          })}
          {finalLogos.map((logo, index) => {
            return (
              <div
                className={classNames(Styles.imageContainer, imageContainerClassName)}
                key={index}>
                <Image
                  alt={logo}
                  height={50}
                  width={100}
                  layout='intrinsic'
                  className={classNames(Styles.image, imageClassName)}
                  src={`/img/logos/${isColor ? 'color/' : 'white/'}${logo}.svg`}
                />
              </div>
            );
          })}*/}
        </div>
      </Section>
    </SectionContainer>
  );
};

LogoSection.propTypes = {
  className: PropTypes.string,
  imageContainerClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  removeBackground: PropTypes.bool,
  isColor: PropTypes.bool,
  opacity: PropTypes.number,
};

export default LogoSection;
